import { App } from 'vue'
import { setupDevtools } from './devtools'
import { configureAuthorizationHeaderInterceptor } from './interceptors'
import { configureNavigationGuards } from './navigationGuards'
import { AuthOptions, AuthPlugin, RequiredAuthOptions } from './types'
import { setupAuthPluginAuth0 } from './providers/auth0'
import { setupAuthPluginMSAL } from './providers/msal'
import { setupAuthPluginOAuth2 } from './providers/oauth2'

export let authInstance: AuthPlugin | undefined = undefined

const defaultOptions = {
  autoConfigureNavigationGuards: true,
  defaultPictureURL: '/user.svg',
}

export function createAuth(appOptions: AuthOptions) {
  // Fill default values to options that were not received
  const options: RequiredAuthOptions = { ...defaultOptions, ...appOptions }

  return {
    install: (app: App): void => {
      if (options.provider.name === 'msal') {
        authInstance = setupAuthPluginMSAL(options, options.provider.config)
      } else if (options.provider.name === 'auth0') {
        authInstance = setupAuthPluginAuth0(options, options.provider.config)
      } else {
        authInstance = setupAuthPluginOAuth2(options, options.provider.config)
      }

      app.config.globalProperties.$auth = authInstance

      if (options.autoConfigureNavigationGuards) {
        configureNavigationGuards(options.router)
      }

      if (options.axios?.autoAddAuthorizationHeader) {
        configureAuthorizationHeaderInterceptor(options.axios.instance, options.axios.authorizationHeaderPrefix)
      }

      if (import.meta.env.DEV) {
        // @ts-expect-error: until it gets fixed in devtools
        setupDevtools(app, authInstance)
      }
    },
  }
}
