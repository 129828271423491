import {
  PassportPublic,
  DataObjectGet,
  DataObjectCreate,
  CompareEnum,
} from '@coac-gmbh/saifty-query-builder/dist/models'

export interface QueryBuilderManager extends PassportPublic {
  isSelected: boolean
}

export interface QueryComponentWithControls extends DataObjectGet {
  isSelected: boolean
}

export enum QueryTypes {
  EHS = 'EHS',
  KVS = 'KVS',
}

export function getDefaultEHSComponentData(): DataObjectCreate {
  return {
    name: '',
    operation: '',
    type: QueryTypes.EHS.toLowerCase(),
    objects: [
      {
        name: '',
        aggregate: '',
        specification: {
          aggregate: '',
          system: [],
          identifier: {},
          rating: [],
          val_area: [],
          lang: [],
          attribute: [],
        },
      },
    ],
  }
}

export function getDefaultKVSComponentData(): DataObjectCreate {
  return {
    name: '',
    operation: '',
    type: QueryTypes.KVS.toLowerCase(),
    objects: [
      {
        name: '',
        aggregate: '',
        specification: {
          attribute: [],
          aggregate: '',
          key_source: '',
          key_name: '',
          key_date: {
            from_date: '',
            to_date: '',
            filter: '' as CompareEnum, // =, >, <, <=, >=, !=
          },
          key_group: [],
          key_subgroup: [],
          key_product: [],
          key_bus_unit: [],
          key_region: [],
          key_language: [],
          key_rating: [],
        },
      },
    ],
  }
}
