import { ref, watchEffect } from 'vue'
import { AppUser } from '../types'
import { accountManagementApi } from '@/api'
import { UserSource } from '@coac-gmbh/saifty-main-apis'
import axios from 'axios'
import useRolesAndPermissions, { Roles, Permissions } from '@/composables/useRolesAndPermissions'

export default function useProviderData() {
  const { ROLES_WITH_PERMISSIONS } = useRolesAndPermissions()
  const isAuthenticated = ref(false)
  const user = ref<AppUser>()
  const loaded = ref(false)
  const error = ref<string>()
  const permissionList = ref<string[]>([])
  const loadPermissionFromTheFrontend =
    (import.meta.env.VITE_LOAD_PERMISSIONS_FROM_THE_FRONTEND &&
      import.meta.env.VITE_LOAD_PERMISSIONS_FROM_THE_FRONTEND === 'true') ||
    window.ENV.LOAD_PERMISSIONS_FROM_THE_FRONTEND

  async function waitUntilLoaded(): Promise<void> {
    if (loaded.value) {
      return
    }

    return new Promise((resolve) => {
      const stopLoadedWatcher = watchEffect(() => {
        if (loaded.value) {
          resolve()
          stopLoadedWatcher()
        }
      })
    })
  }

  async function syncUserWithBackend(email: string, userProviderSource: UserSource, idToken: string) {
    try {
      const { data: account } = await accountManagementApi.getUserByEmail({
        email,
      })

      await accountManagementApi.updateRoleGroups({
        accountId: account.id,
        updateRoleGroupsRequest: { idToken },
      })
      await loadPermissions()
    } catch (error: any) {
      if (axios.isAxiosError(error) && error && error.response && error.response.status === 404) {
        await accountManagementApi.register({
          userRequest: { email, source: userProviderSource, idToken },
        })

        await loadPermissions()
      } else {
        throw new Error('Error verifying user in the server')
      }
    }
  }

  async function loadPermissions() {
    if (!loadPermissionFromTheFrontend) {
      const { data: backendPermissions } = await accountManagementApi.whoiam()
      permissionList.value = backendPermissions.authorities || []
      if (permissionList.value.includes('ROLE_ADMIN')) {
        // Set all permissions if backend returns ROLE_ADMIN
        permissionList.value = Object.values(Permissions)
      }
    }
  }

  function getUserPermissions(idToken?: { [key: string]: any }) {
    if (permissionList.value.length === 0 || loadPermissionFromTheFrontend) {
      let userFallbackRoles = import.meta.env.VITE_USER_FALLBACK_ROLES || window.ENV.USER_FALLBACK_ROLES
      // Default unsustituted valued because of missing ENV variable
      if (userFallbackRoles === '**USER_FALLBACK_ROLES**') {
        userFallbackRoles = undefined
      }

      const roleAdminTokenSynonyms =
        import.meta.env.VITE_ROLE_ADMIN_TOKEN_SYNONYMS || window.ENV.ROLE_ADMIN_TOKEN_SYNONYMS
      let roleAdminTokenSynonymsArray: string[] = []
      if (roleAdminTokenSynonyms) {
        roleAdminTokenSynonymsArray = roleAdminTokenSynonyms.split(',').map((r) => r.trim())
      }

      const roleMemberTokenSynonyms =
        import.meta.env.VITE_ROLE_MEMBER_TOKEN_SYNONYMS || window.ENV.ROLE_MEMBER_TOKEN_SYNONYMS
      let roleMemberTokenSynonymsArray: string[] = []
      if (roleMemberTokenSynonyms) {
        roleMemberTokenSynonymsArray = roleMemberTokenSynonyms.split(',').map((r) => r.trim())
      }

      const saiftyRoles: Roles[] = []

      const authIdTokenRolesClaim =
        import.meta.env.VITE_AUTH_ID_TOKEN_ROLES_CLAIM || window.ENV.AUTH_ID_TOKEN_ROLES_CLAIM

      if (authIdTokenRolesClaim && idToken && authIdTokenRolesClaim in idToken) {
        const tokenRoles = idToken[authIdTokenRolesClaim] as string[]
        if (tokenRoles.includes(Roles.Admin) || roleAdminTokenSynonymsArray.some((r) => tokenRoles.includes(r))) {
          saiftyRoles.push(Roles.Admin)
        }
        if (tokenRoles.includes(Roles.Member) || roleMemberTokenSynonymsArray.some((r) => tokenRoles.includes(r))) {
          saiftyRoles.push(Roles.Member)
        }

        // Fallback in case the token exists but has a unknown role
        if (saiftyRoles.length === 0 && userFallbackRoles) {
          const userFallbackRolesArray = userFallbackRoles.split(',').map((r) => r.trim())
          if (userFallbackRolesArray.includes(Roles.Admin)) {
            saiftyRoles.push(Roles.Admin)
          }
          if (userFallbackRolesArray.includes(Roles.Member)) {
            saiftyRoles.push(Roles.Member)
          }
        }
      } else if (userFallbackRoles) {
        const userFallbackRolesArray = userFallbackRoles.split(',').map((r) => r.trim())
        if (userFallbackRolesArray.includes(Roles.Admin)) {
          saiftyRoles.push(Roles.Admin)
        }
        if (userFallbackRolesArray.includes(Roles.Member)) {
          saiftyRoles.push(Roles.Member)
        }
      }

      const allPermissionsByRoles = ROLES_WITH_PERMISSIONS.filter((role) =>
        saiftyRoles.some((saiftyRole: string) => role.type === saiftyRole)
      ).flatMap((role) => role.permissions)

      // Remove duplicates
      return [...new Set(allPermissionsByRoles)]
    } else {
      return permissionList.value
    }
  }

  return {
    isAuthenticated,
    user,
    loaded,
    error,
    waitUntilLoaded,
    syncUserWithBackend,
    getUserPermissions,
  }
}
